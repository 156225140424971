import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/detail/:id',
    name: 'detail',
    component: () => import('../views/Detail.vue'),
    props: true
  },
  {
    path: '/appraiser-detail/:id',
    name: 'appraiser-detail',
    component: () => import('../views/AppraiserDetail.vue'),
    props: true
  },
  {
    path: '/qr-scanner',
    name: 'qr-scanner',
    component: () => import('../views/QrCodeScanner.vue'),
    props: true
  },
  {
    path: '*',
    name: 'notFound',
    component: () => import('../views/NotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
        // , offset: { x: 0, y: 10 }
      }
    }
  },
  routes
})

export default router
