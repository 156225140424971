import API from '@/api'
export default {
  namespaced: true,
  state: () => ({
    disciplines: []
  }),
  getters: {
    mapDisciplines: state => {
      return state.disciplines.map(el => {
        return {
          ids: el.ids,
          name: el.name
        }
      })
    }
  },
  mutations: {
    SET_DISCIPLINES(state, disciplines) {
      state.disciplines = disciplines
    }
  },
  actions: {
    getDisciplines({ commit }) {
      API.disciplines.get().then(response => {
        commit('SET_DISCIPLINES', response.data.data)
      })
    }
  }
}
