export default {
  namespaced: true,
  state: () => ({
    searching: false,
    hidden: true
  }),
  mutations: {
    SET_SEARCHING(state, searching) {
      state.searching = searching
    },
    SET_HIDDEN(state) {
      state.hidden = !state.hidden
    }
  }
}
