export default {
  namespaced: true,
  state: () => ({
    selectedNames: '',
    selectedDecisionNum: '',
    selectedAppraiserDecisionNum: '',
    selectedRegion: null,
    selectedCategory: null,
    selectedAppraiserRegulationSpeciality: null,
    selectedDiscipline: null,
    selectedRegulation: null,
    selectedExtent: null,
    pagination: {
      page: 1,
      limit: 20
    }
  }),
  getters: {
    queryNotEmpty: (state, getters) => {
      return (
        getters.testNames ||
        getters.testDecisionNum ||
        state.selectedRegion ||
        state.selectedCategory ||
        state.selectedDiscipline
      )
    },
    appraiserQueryNotEmpty: (state, getters) => {
      return (
        getters.testNames ||
        getters.testAppraiserDecisionNum ||
        state.selectedRegion ||
        state.selectedDiscipline ||
        state.selectedAppraiserRegulationSpeciality
      )
    },
    testNames: state => {
      return (
        /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ\- ]+$/.test(state.selectedNames) &&
        state.selectedNames.length > 2
      )
    },
    testDecisionNum: state => {
      return state.selectedDecisionNum && state.selectedDecisionNum.length > 3
    },
    testAppraiserDecisionNum: state => {
      return (
        state.selectedAppraiserDecisionNum &&
        state.selectedAppraiserDecisionNum.length > 3
      )
    },
    mapNames: (state, getters) => {
      return getters.testNames
        ? state.selectedNames.replace(/\s+/g, ' ').split(' ')
        : false
    },
    page: state => {
      return state.pagination.page
    },
    getQueryParams: (state, getters) => {
      return {
        ...(getters.mapNames && { 'filter[names][match]': getters.mapNames }),
        ...(state.selectedDecisionNum && {
          'filter[number][match]': state.selectedDecisionNum
        }),
        ...(state.selectedRegion && {
          'filter[voivodeship_id]': state.selectedRegion
        }),
        ...(state.selectedCategory && {
          'filter[category_id]': state.selectedCategory
        }),
        ...(state.selectedDiscipline && {
          'filter[discipline_id]': state.selectedDiscipline
        }),
        ...(state.selectedExtent && {
          'filter[extent_id]': state.selectedExtent
        }),
        ...(state.pagination.limit && { 'page[size]': state.pagination.limit }),
        ...(getters.page && { 'page[number]': getters.page }),
        ...{ 'stats[total]': 'count' }
      }
    },
    getAppraiserQueryParams: (state, getters) => {
      return {
        ...(getters.mapNames && { 'filter[names][match]': getters.mapNames }),
        ...(state.selectedAppraiserDecisionNum && {
          'filter[permit_number][match]': state.selectedAppraiserDecisionNum
        }),
        ...(state.selectedRegion && {
          'filter[voivodeship_id]': state.selectedRegion
        }),
        ...(state.selectedDiscipline && {
          'filter[discipline_id]': state.selectedDiscipline
        }),
        ...(state.selectedAppraiserRegulationSpeciality && {
          'filter[speciality_id]': state.selectedAppraiserRegulationSpeciality
        }),
        ...(state.pagination.limit && { 'page[size]': state.pagination.limit }),
        ...(getters.page && { 'page[number]': getters.page }),
        ...{ 'stats[total]': 'count' }
      }
    }
  },
  mutations: {
    SET_NAMES(state, val) {
      state.selectedNames = val.trim()
    },
    SET_DECISION(state, val) {
      state.selectedDecisionNum = val.trim()
    },
    SET_APPRAISER_DECISION(state, val) {
      state.selectedAppraiserDecisionNum = val.trim()
    },
    SET_APPRAISER_REGULATION_SPECIALITY(state, val) {
      state.selectedAppraiserRegulationSpeciality = val
    },
    SET_REGION(state, val) {
      state.selectedRegion = val
    },
    SET_CATEGORY(state, val) {
      state.selectedCategory = val
    },
    SET_DISCIPLINE(state, val) {
      state.selectedDiscipline = val
    },
    SET_REGULATION(state, val) {
      state.selectedRegulation = val
    },
    SET_EXTENT(state, val) {
      state.selectedExtent = val
    },
    NEXT_PAGE(state) {
      state.pagination.page++
    },
    PREVIOUS_PAGE(state) {
      state.pagination.page--
    }
  }
}
