import API from '@/api'
export default {
  namespaced: true,
  state: () => ({
    categories: []
  }),
  getters: {
    mapCategories: state => {
      return state.categories.map(el => {
        return {
          id: el.id,
          name: el.attributes.name,
          extents: el.attributes.extents
        }
      })
    },
    mapExtents: (state, getters, rootState) => {
      return rootState.selectedSet.selectedCategory
        ? getters.mapCategories
            .find(el => el.id === rootState.selectedSet.selectedCategory)
            .extents.map(extent => {
              return {
                name: extent.name,
                ids: extent.ids,
                specialities: extent.specialities
              }
            })
            .filter(ex => ex.name !== 'brak')
        : null
    },
    categoryName: (state, getters, rootState) => {
      return rootState.selectedSet.selectedCategory
        ? getters.mapCategories.find(
            el => el.id === rootState.selectedSet.selectedCategory
          ).name
        : null
    },
    extentName: (state, getters, rootState) => {
      return rootState.selectedSet.selectedExtent
        ? getters.mapExtents.find(
            el => el.ids === rootState.selectedSet.selectedExtent
          ).name
        : null
    },
    findSpecialities: (state, getters, rootState) => {
      return rootState.selectedSet.selectedExtent
        ? getters.mapExtents.find(
            el => el.ids === rootState.selectedSet.selectedExtent
          ).specialities
        : null
    }
  },
  mutations: {
    SET_CATEGORIES(state, categories) {
      state.categories = categories
    }
  },
  actions: {
    getCategories({ commit }) {
      API.categories.get().then(response => {
        commit('SET_CATEGORIES', response.data.data)
      })
    }
  }
}
