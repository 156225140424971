import API from '@/api'
export default {
  namespaced: true,
  state: () => ({
    voivodeships: []
  }),
  getters: {
    mapVoivodeships: state => {
      return state.voivodeships.map(el => {
        return {
          id: el.id,
          name: el.attributes.name
        }
      })
    }
  },
  mutations: {
    SET_VOIVODESHIPS(state, voivodeships) {
      state.voivodeships = voivodeships
    }
  },
  actions: {
    getVoivodeships({ commit }) {
      API.voivodeships.get().then(response => {
        commit('SET_VOIVODESHIPS', response.data.data)
      })
    }
  }
}
