import API from '@/api'
export default {
  namespaced: true,
  state: () => ({
    regulations: []
  }),
  getters: {
    mapRegulations: state => {
      let index = 0
      return state.regulations.map(el => {
        return {
          index: index++,
          name: el.regulation_display_name || el.regulation_name,
          specialities: el.specialities
        }
      })
    }
  },
  mutations: {
    SET_REGULATIONS(state, regulations) {
      state.regulations = regulations
    }
  },
  actions: {
    getRegulations({ commit }) {
      API.regulations.get().then(response => {
        commit('SET_REGULATIONS', response.data.data)
      })
    }
  }
}
