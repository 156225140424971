import API from '@/api'

export default {
  namespaced: true,
  state: () => ({
    appraiserCards: [],
    totalRecords: 0
  }),
  getters: {
    mapResults: state => {
      return state.appraiserCards.map(el => {
        return {
          id: el.id,
          names: el.attributes?.decision?.names || '-',
          decision_number: el.attributes?.decision?.decision_number || '-',
          permission_number: el.attributes?.decision?.permission_number || '-',
          permit_number: el.attributes?.decision?.permit_number || '-',
          expiriation_date: el.attributes?.expiriation_date,
          country: el.attributes?.postal_address?.country || '-',
          voivodeship: el.attributes?.postal_address?.voivodeship || '-',
          zip_code: el.attributes?.postal_address?.zip_code || '-',
          post_office: el.attributes?.postal_address?.post_office || '-',
          place: el.attributes?.postal_address?.place || '-',
          street: el.attributes?.postal_address?.street || '-',
          house_number: el.attributes?.postal_address?.house_number || '-',
          apartment_number:
            el.attributes?.postal_address?.apartment_number || '-',
          primary_voivodeship:
            el.attributes?.personal_details?.primary_voivodeship || '-',
          professional_title:
            el.attributes?.personal_details?.professional_title || '-',
          academic_degree:
            el.attributes?.personal_details?.academic_degree || '-',
          memberships: el.attributes?.personal_details?.memberships,
          email_address: el.attributes?.email_address || '-',
          phone_number: el.attributes?.phone_number || '-',
          specialities:
            el.attributes?.specialities?.map(spec => {
              return {
                categories: spec?.speciality_categories,
                discipline: spec?.discipline,
                speciality: spec?.speciality,
                extent: spec?.extent
              }
            }) || []
        }
      })
    }
  },
  mutations: {
    SET_APPRAISER_CARDS(state, payload) {
      state.appraiserCards = payload
    },
    SET_TOTAL(state, totalRecords) {
      state.totalRecords = totalRecords
    }
  },
  actions: {
    getAppraiserCards({ commit }, params) {
      API.appraiserCards.get(params).then(response => {
        if (response.data?.data?.length < 1) {
          this._vm.$toast.warning(API.appraiserCards.empty)
          commit('base/SET_HIDDEN', null, { root: true })
          commit('SET_APPRAISER_CARDS', [])
          commit('selectedSet/SET_NAMES', '', { root: true })
          commit('selectedSet/SET_DECISION', '', { root: true })
          commit('selectedSet/SET_REGION', null, { root: true })
          commit('selectedSet/SET_CATEGORY', null, { root: true })
          commit('selectedSet/SET_DISCIPLINE', null, { root: true })
          commit('selectedSet/SET_EXTENT', null, { root: true })
          commit('selectedSet/SET_APPRAISER_DECISION', '', { root: true })
        } else {
          commit('SET_APPRAISER_CARDS', response.data.data)
          commit('SET_TOTAL', response.data.meta?.stats?.total?.count)
        }
      })
    }
  }
}
